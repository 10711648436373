const changeZero = function (num) {
  if (num == 0) {
    num = '-'
  }
  return num
}

const interpo = function (value) {
  if (value == null || value == '') {
    return ''
  }
  let str = ''
  if (value.length == 1) {
    str = '0' + value
  } else {
    str = value
  }
  return str
}

export { changeZero, interpo }