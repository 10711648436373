import Axios from 'axios'
import Router from './router'
import { Notification } from 'element-ui'
import store from "./store";

// 请求拦截器
Axios.interceptors.request.use(config => {
    const TOKEN = localStorage.getItem('TOKEN')
    let host = window.location.hostname
    if (host == 'localhost') {
        config.baseURL = 'http://www.newluming.com/index.php/api/' // 服务器地址
    } else {
        config.baseURL = 'https://bkgaoshou.com/index.php/api/'
    }

    // config.baseURL = 'http://www.newluming.com/index.php/api/' // 服务器地址
    // config.baseURL = 'https://bkgaoshou.com/index.php/api/'

    config.headers['Content-Type'] = 'application/json'
    if (TOKEN) {
        config.headers['Token'] = TOKEN
    }
    return config
}, function (error) { // 请求失败
    Notification({
        offset: 200,
        message: '网络链接异常！',
        type: 'error'
    })
    return Promise.reject(error)
})

// 响应拦截器
Axios.interceptors.response.use(function (response) {
    const res = response.data
    switch (res.code) {
        case '200':
            return res.data
        case '201':
        case '300':
            Notification({
                offset: 200,
                message: res.msg,
                type: 'info'
            })
            store.commit('loginOut')
            localStorage.clear();
            Router.push("/Login");
            return false
        case '500':
            if (res.msg) {
                Notification({
                    offset: 200,
                    message: res.msg,
                    type: 'error'
                })
            }
            return false
        case '301':
            return res
        default:
            return res
    }
}, function (error) { // 响应失败
    Notification({
        offset: 200,
        message: '网络链接异常！',
        type: 'error'
    })
    return Promise.reject(error)
})
export default Axios
