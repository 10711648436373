import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        userInfo: JSON.parse(localStorage.getItem("UserInfo")),
        login: localStorage.getItem('TOKEN'),
        logo: '',
        config: '',
        changeArtState: 1,

        //填报
        oldLineScore: {
        },
        equalScore: {
        },
        record: {
            id: [],
            dg: [],
            sp: [],
        },
        reportTable: null,
        batch: '',
        scoreLine: {},
        tableName: '',
        report_id: null,
        artFormList: []
    },
    mutations: {
        setInfo(state, info) {
            state.userInfo = info
        },
        loginOut(state) {
            state.userInfo = ''
        },
        setLogo(state, logo) {
            state.logo = logo
        },
        setConfig(state, data) {
            state.config = data
        },

        //填报
        setOldLineScore(state, data) {
            state.oldLineScore = data
        },
        setEqualScore(state, data) {
            state.equalScore = data
        },
        setRecord(state, data) {
            state.record = data
        },
        setReportTable(state, data) {
            state.reportTable = data
        },
        setBatch(state, data) {
            state.batch = data
        },
        setScoreLine(state, data) {
            state.scoreLine = data
        },
        setTableName(state, data) {
            state.tableName = data
        },
        setReportId(state, data) {
            state.report_id = data
        },
        setArtFromList(state, data) {
            state.artFormList = data;
            state.changeArtState = state.changeArtState + 1
        }
    },
    actions: {},
    modules: {}
})
