<template>
  <div id="LuHead">
    <div class="head">
      <el-row type="flex" align="middle">
        <el-col :span="5" :offset="1">
          <img :src="$store.state.logo" class="logo" style="width: 180px" alt="" />
        </el-col>
        <el-col :span="8" :offset="1">
          <el-input placeholder="查学校/查专业/查资讯" v-model="search" @keyup.enter.native="click"
            class="input-with-select search">
            <el-button slot="append">
              <img @click="click" src="@/assets/img/home/search.png" alt="" />
            </el-button>
          </el-input>
        </el-col>

        <!-- 头像 用户名-->
        <el-col :span="5" :offset="4" v-if="userInfo">
          <router-link to="/user/info" style="overflow: hidden; white-space: nowrap">
            <el-avatar v-if="userInfo.head_img" :size="30" :src="userInfo.head_img" />
            <template v-else>
              <img class="default_img" v-if="userInfo.is_sex == 1" src="@/assets/img/user_info/man.png" alt="" />
              <img class="default_img" v-if="userInfo.is_sex == 0" src="@/assets/img/user_info/girl.png" alt="" />
            </template>
            <span> 个人中心</span>
          </router-link>
        </el-col>

        <el-col :span="5" :offset="3" v-else>
          <router-link to="/register" style="color:#ed1c24;font-size:18px">
            <img src="@/assets/img/login/reg.png" alt="" style="vertical-align: middle;">
            <span style="vertical-align: middle;"> 会员卡激活</span>
          </router-link>

          <span style="margin:0 15px;border-right:1px solid  rgb(149 149 149)"> </span>

          <router-link to="/Login" style="color:#2771fa;font-size:18px">
            <img src="@/assets/img/login/login.png" alt="" style="vertical-align: middle;">
            <span style="vertical-align: middle;"> 注册登录</span>
          </router-link>
        </el-col>
      </el-row>
    </div>
    <div class="nav">
      <el-menu class="menu" :router="true" mode="horizontal" background-color="#4870f6" text-color="#fff"
        active-text-color="#fff">
        <el-menu-item index="1" route="/">首页</el-menu-item>
        <el-submenu index="2">
          <template slot="title">新闻动态</template>
          <el-menu-item index="2-1" route="/NewsList?type=0">高考资讯
          </el-menu-item>
          <el-menu-item index="2-2" route="/NewsList?type=1">高校新闻
          </el-menu-item>
          <el-menu-item index="2-3" route="/NewsList?type=3">公司动态
          </el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">智能查询</template>
          <el-menu-item index="3-1" route="/lookCollege">找大学</el-menu-item>
          <el-menu-item index="3-2" route="/lookSpecialty">查专业</el-menu-item>
          <el-menu-item index="3-3" route="/provinceLine">省控线 </el-menu-item>
          <el-menu-item index="3-4" route="/ranking">位次查询</el-menu-item>
          <el-menu-item index="3-5" route="/lookData">数据看板 </el-menu-item>


        </el-submenu>
        <el-submenu index="4">
          <template slot="title">智能填报</template>
          <el-menu-item index="4-1" route="/volunteer">
            普通类填报系统
          </el-menu-item>
          <el-menu-item index="4-2" route="/volunteer_art">
            美术类填报系统
          </el-menu-item>
        </el-submenu>
        <el-submenu index="8">
          <template slot="title">智能选科</template>
          <div class="tb">
            <el-menu-item route="/newChooseSubject" index="8-1">
              选科概况
            </el-menu-item>
            <el-menu-item route="/newChooseSubject/newSpecialtySearch" index="8-2">
              选科查询
            </el-menu-item>
            <el-menu-item route="/newChooseSubject/chooseSpecialty" index="8-3">
              智能选科
            </el-menu-item>
            <el-menu-item route="/newChooseSubject/newSubjectUser" index="8-4">
              我的选科
            </el-menu-item>
          </div>
        </el-submenu>
        <el-submenu popper-class="submenu" index="7">
          <template slot="title">特殊类型招生</template>
          <div class="tb">
            <el-menu-item index="7-6" route="/advanceBatch">提前批 </el-menu-item>
            <el-menu-item>
              <a class="a-color" href="https://gaokao.chsi.com.cn/gkxx/zt/qjjh2024.shtml" target="_blank">强基计划（阳光高考）</a>
            </el-menu-item>
            <el-menu-item>
              <a class="a-color" href="https://gaokao.chsi.com.cn/gkxx/zt/2024sdzx.shtml" target="_blank">专项计划（阳光高考）</a>
            </el-menu-item>
          </div>

        </el-submenu>
        <el-submenu index="5">
          <template slot="title">生涯测评</template>
          <div class="tb">
            <el-menu-item route="/eq" index="5-1">
              情绪智力程度测评
            </el-menu-item>
            <el-menu-item route="/scl" index="5-2">
              心理健康程度测评
            </el-menu-item>
            <el-menu-item route="/holland" index="5-3">
              霍兰德测评
            </el-menu-item>

            <el-menu-item route="/interest" index="5-5">
              大学专业选择测评（新高考）
            </el-menu-item>
          </div>
        </el-submenu>
        <el-menu-item index="6" route="/server">服务</el-menu-item>

      </el-menu>
    </div>
  </div>
</template>
485830 92
<script>
import { mapState } from "vuex";

export default {
  name: "LuHead",
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      search: "",
      logo: "",
    };
  },
  methods: {
    click() {
      this.$router.push("/search?kwd=" + this.search);
      this.search = "";
    },
  },
};
</script>

<style lang="less">
#LuHead {
  line-height: 50px;
  min-width: 1200px;
  height: 170px;
  overflow: hidden;
  position: relative;

  .head {
    width: 1200px;
    margin: 30px auto;

    .logo {
      float: left;
    }

    .search {
      .el-input__inner {
        text-indent: 1rem;
        letter-spacing: 3px;
        height: 40px;
        border: 1px solid #3479f8;
        border-radius: 30px 0 0 30px;
        border-right: 0;
      }

      .el-input-group__append,
      .el-input-group__prepend {
        background: white;
        border: 1px solid #3479f8;
        border-left: 0;
        border-radius: 0 30px 30px 0;
      }
    }
  }

  .default_img {
    float: left;
    margin-top: 3px;
    margin-right: 8px;
    width: 40px;
  }

  .nav {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 55px;
    line-height: 55px;

    .el-submenu__title i {
      color: white;
    }

    .el-menu.el-menu--horizontal {
      border-bottom: 0;
    }

    background: #4870f6;

    .menu {
      font-size: 16px !important;
      width: 1200px;
      margin: 0 auto;
    }

    .el-menu-item {
      font-size: 18px;
      padding: 0 17px;
    }

    .el-submenu__title {
      font-size: 18px;
      padding: 0 36px;
    }

    .el-menu--horizontal>.el-menu-item {
      height: 55px;
      line-height: 55px;
    }

    .el-menu--horizontal>.el-submenu .el-submenu__title {
      height: 55px;
      line-height: 55px;
    }
  }

  .el-input__inner {
    font-size: 18px;
  }
}

.a-color {
  color: white;
}
</style>
