<template>
  <div id="footer">
    <div class="footer">
      <div class="fl-l">
        <div class="info">
          <div>简介</div>
          <div>
            <router-link to="/detail?id=5">联系我们</router-link>
          </div>
          <div>
            <router-link to="/detail?id=4">关于我们</router-link>
          </div>
        </div>
        <div class="info">
          <div>
            <router-link to="/help">新手帮助</router-link>
          </div>
          <div>
            <router-link to="/lookCollege">智能查询</router-link>
          </div>
          <div>
            <router-link to="/volunteer">智能填报</router-link>
          </div>
        </div>
        <div class="info">
          <div><a href="https://daoshi.bkgaoshou.com/#/">导师平台</a></div>
          <div>忘记密码</div>
          <div>在线支付</div>
        </div>
        <div class="info1">
          <div><a href="https://www.haeea.cn/index.shtml"  target="_blank">河南省教育考试院</a></div>
          <div><a href="https://pzwb.haeea.cn/stu"  target="_blank">河南省普通高校招生考生服务平台</a></div>
        </div>
      </div>
      <div class="fl-r">
        <div class="img">
          <img :src="info.wx_img" alt="">
        </div>
        <div class="time">
          <p>400-000-3985</p>
          <p>周一至周五</p>
          <p>上午 9：00-12：00</p>
          <p>下午14：00-17：00</p>
        </div>
      </div>
    </div>
    <div class="foot">
      <span>
        鹿鸣教育@
      </span>
      <span>
        <a href="https://beian.miit.gov.cn" target="_blank">
          豫ICP备19038519号
        </a>
      </span>
      <span>
        <router-link to='detail?id=6'>法律声明</router-link>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LuFooter",
  data() {
    return {
      info: {},
      list: [],
    };
  },

  mounted() {
    let config = this.$store.state.config;
    if (config) {
      this.info = config;
    } else {
      const res = {
        girl: "https://img.bkgaoshou.com/img/girl.png",
        id: "1",
        logo: "https://img.bkgaoshou.com/img/20191128/2874c2f56dbb8f7ea57f24c24a8460ce.png",
        mid_img:
          "https://img.bkgaoshou.com/img/20191129/d7e96c2f4eb7dadc42a15fc39cc3cfeb.jpg",
        mobile: "400-000-3985",
        name: "鹿鸣教育@2019",
        qq: "419266508",
        score_banner:
          "https://img.bkgaoshou.com/img/20191126/2701edd2908ff6438f3d010280d48174.jpg",
        time: "营业时间：周一至周五上午9:00～12:00下午14:00～17:00",
        vip_banner:
          "https://img.bkgaoshou.com/img/20191115/1729a2758b1528e3ea71acfbcbd34f6a.png",
        warming:
          "以下方案仅供参考，不作为决策依据，最终志愿填报需在各地教育部门指定网址进行填写。",
        wx_img:
          "https://img.bkgaoshou.com/img/20191223/70b520a0fc161f24461dd71de31e46ea.jpg",
      };
      this.info = res;
    }

    const lists = [
      { id: "5", title: "联系我们" },
      { id: "4", title: "关于我们" },
      { id: "6", title: "法律声明" },
    ];
    this.list = lists;
  },
};
</script>

<style lang="less" scoped>
#footer {
  overflow: hidden;
  background-color: #434040;
  .footer {
    height: 150px;
    width: 1200px;
    margin: 15px auto 0;
    font-size: 16px;
    color: #999999;
    display: flex;
    justify-content: space-between;
    .fl-l {
      display: flex;
      margin-top: 20px;
      .info {
        width: 130px;
        div:first-child {
          margin-bottom: 18px;
        }
        div:nth-child(2) {
          margin-bottom: 7px;
        }
      }
      .info1 {
        width: 250px;
        div:first-child {
          margin-bottom: 18px;
        }
        div:nth-child(2) {
          margin-bottom: 7px;
        }
      }
      a {
        color: #999999;
      }
      a:hover {
        color: #f2f2f2;
      }
    }
    .fl-r {
      margin-top: 20px;
      display: flex;
      img {
        width: 100px;
      }
      .time {
        margin-left: 15px;
        margin-right: 20px;
        p:first-child {
          color: #f2f2f2;
          font-size: 18px;
          margin-top: 2px;
          margin-bottom: 12px;
        }
      }
    }
  }

  .foot {
    background: #353434;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #999999;
    span {
      padding: 8px;
    }
    a {
      color: #999999;
    }
  }
}
</style>